import React from 'react'
import phone from './telephone_724664.png'

export const Phone = () => {
  return (
    <section className='whatsapp fixed bottom-2 right-1 w-[50px] h-[50px] md:w-[100px]h-[100px] z-50 '>
        <a target='blank' href='tel:919315453989'>
            <img className='w-full h-full object-cover object-center ' src={phone} alt='whatsapp' />
        </a>
    </section>
  )
}
