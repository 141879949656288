import './App.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Header from './Component/Header/Header';
import Home from './Component/Home/Home';
import SingleProduct from './Component/SingleProduct/SingleProduct';
import Footer from './Component/Footer/Footer';
import { Whatsapp } from './Component/Whatsapp/Whatsapp';
import About from './Component/About/About';
import Contact from './Component/Contact/Contact';
import Testimonial from './Component/Testimonial/Testimonial';
import Product from './Component/Products/Product';
import { Phone } from './Component/Phone/Phone';


function App() {
  return (
   <>
    <BrowserRouter>
        <Header/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/SingleProduct/:id" element={<SingleProduct />} />
        <Route path='/category/SingleProduct/:id' element={<SingleProduct />} />
        <Route path='/category' element={<Product/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/Testimonial' element={<Testimonial />} />
      </Routes>
      <Footer/>
      <Whatsapp/>
      <Phone/>
    </BrowserRouter>
   </>
  );
}

export default App;
