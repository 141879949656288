import React from "react";
import "./About.css";

const About = () => {
  return (
    <section className="about-section">
      <div className="heading ">
        <h1>Make Your Event Memorable With Us </h1>
        <p>
          Transform your special moments into unforgettable memories with our
          exceptional service. Let's craft a celebration that's uniquely yours,
          cherished for a lifetime.
        </p>
      </div>
      <div className="about w-full flex flex-col items-center justify-center md:flex-row gap-5 ">
        <div className="about-left ">
          <div className="pol">
            <h1>#DL DECORATION</h1>
          </div>
          <div className="about-content w-full flex flex-col gap-7  ">
            <h1 className="color-[#76CCBD]">
               Balloon Decoration in Delhi
            </h1>
            <p>
              At DL Decoration, we specialize in premium balloon decoration for
              every occasion across Delhi. Whether you're planning a birthday
              party, wedding reception, or a corporate event, our skilled team
              of decorators is dedicated to creating memorable experiences with
              unique and vibrant balloon displays. We offer a wide range of
              balloon decor options, from elegant balloon arches and stunning
              balloon bouquets to eye-catching balloon backdrops and
              personalized balloon garlands. With years of experience in the
              industry, DL Decoration brings creativity, professionalism, and an
              eye for detail to every project. We work with clients to design
              custom balloon decorations that align perfectly with your event
              theme and color palette. No matter where you are in Delhi, we
              provide reliable and timely services, making us your go-to choice
              for balloon decoration near you. Let us help you transform your
              venue with our innovative balloon decor ideas and make your event
              unforgettable. Contact DL Decoration today for the best balloon
              decorators in Delhi.
            </p>
          </div>
        </div>

        <div className="about-right ">
          <div className="about-img">
            <img
              src="https://static.showit.co/800/zonX_xmeQ9Oi_Zmx8jNQPw/209024/7e243388-ae6a-4541-9365-a8bc31c555a6_1_105_c.jpg"
              alt="about"
            />
          </div>
          <div className="pol pol2">
            <h1>SPECIAL EVENTS</h1>
          </div>
          <div className="pol pol3">
            <h1>HOME CELEBRATION</h1>
          </div>
        </div>
      </div>
      <div className="btn">
        <a href="/about">
          Request For Booking <i className="ri-arrow-right-line"></i>{" "}
        </a>
      </div>
    </section>
  );
};

export default About;
