import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import logo from "./logo.png";

const Header = () => {
  const [isopenMenu, setopenMenu] = useState(false);

  const onhandleChange = () => {
    setopenMenu(!isopenMenu);
  };

  const closeMenu = () => {
    setopenMenu(false);
  };

  return (
    <section className="main-header">
      <div className="upper-header flex justify-between px-2 md:px-10">
        <span className="num">
          <a href="tel:+91 9315453989">
            <i className="ri-phone-fill"></i> +91 9315453989 
          </a>
        
          <a href="tel:+91 9821556296">
            +91 9821556296
          </a>
        </span>
        <p>Welcome To DL Decoration</p>
      </div>
      {/* down Header */}
      <div className="down-header flex justify-between items-center">
        <div className="logo_header logo">
          <Link to={'/'}>
            <img src={logo} alt="logo" />
          </Link> 
        </div>
        <nav className="navbar">
          <ul className={`nav-links ${isopenMenu ? "click" : ""} `}>
            <li>
              <Link onClick={closeMenu} to="/">
                Home
              </Link>
            </li>
            <li>
              <Link onClick={closeMenu} to="/about">
                About Us
              </Link>
            </li>
            <li>
              <Link onClick={closeMenu} to="/category">
                Category
              </Link>
            </li>
            <li>
              <Link onClick={closeMenu} to="/testimonial">
                Testimonial
              </Link>
            </li>
            <li>
              <Link onClick={closeMenu} to="/contact">
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
        <div className="language"></div>
        <div className="responsive">
          <i onClick={onhandleChange} className="ri-menu-3-line"></i>
        </div>
      </div>
    </section>
  );
};

export default Header;
