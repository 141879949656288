import React, { useEffect } from "react";
import "./Contact.css";
// import Aos from "aos";

const Contact = () => {
//   useEffect(()=>{
//     Aos.init()
//   },[])
  return (
    <div className="contact-container">
      <div class="contain">
        <div class="wrapper">
          <div class="form" data-aos="fade-right" data-aos-duration="1500">
            <h2 class="form-headline">Drop us message for any Query</h2>
            <form id="submit-form" action="https://formspree.io/f/xqazryyz" method="POST">
              <p>
                <input
                  id="name"
                  name="name"
                  class="form-input"
                  type="text"
                  placeholder="Your Name*"
                />
                <small class="name-error"></small>
              </p>
              <p>
                <input
                  id="email"
                  name="email"
                  class="form-input"
                  type="email"
                  placeholder="Your Email*"
                />
                <small class="name-error"></small>
              </p>
              <p class="full-width">
                <input
                  id="Phone-Number"
                  name="Phone Number"
                  class="form-input"
                  type="text"
                  placeholder="Phone Number*"
                  required
                />
                <small></small>
              </p>
              <p class="full-width">
                <textarea
                  minlength="5"
                  name="mesagge"
                  id="message"
                  cols="30"
                  rows="7"
                  placeholder="Your Message*"
                  required
                ></textarea>
                <small></small>
              </p>
              <p class="full-width">
                <input type="checkbox" id="checkbox" name="checkbox" checked />{" "}
                Yes, I would like to receive communications by call / email
                about Company's services.
              </p>
              <p class="full-width">
                <input
                  type="submit"
                  class="submit-btn"
                  value="Submit"
                  onclick="checkValidations()"
                />
               
              </p>
            </form>
          </div>

          <div class="contacts contact-wrapper"  data-aos="fade-left" data-aos-duration="1500">
            <ul>
              <li><i className="ri-map-pin-line"></i>
                Uttam Nagar New Delhi - 110059  
              </li>
              <li><i className="ri-phone-line"></i>
               <a href="tel:9315453989">+91 9315453989</a> 
                <br/>
                <a href="tel:9821556296">+91 9821556296</a>
              </li>
              <li><i className="ri-mail-line"></i>
              dinesh.rajouriya2@gmail.com
              <br/>monurajouriya25@gmail.com
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
