import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-container">
      <section className="footer">
        <div className="footer-row">
          <div className="footer-col">
            <h4 className="company"> DL Decoration</h4>
            <div className="foote-text">
              <p>
              DL Decoration specializes in creating stunning, custom balloon decorations for all occasions, making your celebrations unforgettable throughout Delhi.
              </p>
              
            </div>
          </div>
          <div className="footer-col">
            <h4>Info</h4>
            <ul className="links">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/category">Category</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/testimonial">Testimonial</Link>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Explore</h4>
            <ul className="links">
              <li>
                <a href="#">Birthday Decoration</a>
              </li>
              <li>
                <a href="#">Anniversary Decoration</a>
              </li>
              <li>
                <a href="#">Welcome Decoration</a>
              </li>
              <li>
                <a href="#">Ring Decoration</a>
              </li>
              <li>
                <a href="#">Car Decoration</a>
              </li>
              <li>
                <a href="#">Kavana Decoration</a>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Legal</h4>
            <ul className="links">
              <li>
                <a href="#">Customer Agreement</a>
              </li>
              <li>
                <a href="#">Privacy Policy</a>
              </li>
              <li>
                <a href="#">Terms</a>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Newsletter</h4>
            <p>
              Subscribe to our newsletter for a weekly dose of news, updates,
              helpful tips, and exclusive offers.
            </p>
            <form action="#">
              <input type="text" placeholder="Your email" required />
              <button type="submit">SUBSCRIBE</button>
            </form>
            <div className="icons">
              <Link to="https://www.instagram.com/delhi_ncr_balloon_decoration?utm_source=qr&igsh=cnpteHByM3gzd2Jr">
                <i className="ri-instagram-line"></i>
              </Link>
              {/* <Link to="https://www.facebook.com/share/wHGVfrZdJK1kUZEJ/?mibextid=qi2Omg">
                <i className="ri-facebook-line"></i>
              </Link> */}
              <a to="https://wa.me/c/919821556296">
                <i className="ri-whatsapp-line"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="copyright">
        <h2>
          @2024 All Right Reseved by{" "}
          <span>
            <Link to="/">DL Decoration</Link>
          </span>
        </h2>
      </div>
    </div>
  );
};

export default Footer;
