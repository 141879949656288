import React from 'react'
import Hero from '../Hero/Hero'
import About from '../About/About'
import Why from '../Why/Why'
import Product from '../Products/Product'
import Testimonial from '../Testimonial/Testimonial'
import Contact from '../Contact/Contact'
import Footer from '../Footer/Footer'

const Home = () => {
  return (
    <>
        <Hero/>
        {/* <Why/> */}
        <About/>
        <Product/>
        <Testimonial/>
        <Contact/>
        
    </>
  )
}

export default Home