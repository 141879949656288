import React, { useState} from 'react'
import './Testimonial.css'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Pagination } from 'swiper/modules';



const Testimonial = () => {
    const [slidesPerView, setSlidesPerView] = useState('2');
    const handleResize = () => {
        const windowWidth = window.innerWidth;
    
        // Adjust slidesPerView based on window width
        if (windowWidth < 400) {
          setSlidesPerView(1);
        } else if (windowWidth >= 400 && windowWidth < 768) {
          setSlidesPerView(2);
        } else {
          setSlidesPerView(2);
        }
      };
   
    
  return (
    <div className='testimonial-container'  data-aos="fade-up"
    data-aos-duration="1000">
        <h2>What Our Client Says <hr/></h2>
        <div className='testimonial'>
        <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
            340: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
          }}
        modules={[FreeMode, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
            <div className='client flex flex-col'>   
                <div className='client-text flex flex-col items-center gap-1'>
                    <p>DL Decoration made our wedding reception absolutely stunning! The balloon decor was elegant, vibrant, and perfectly matched our theme. They transformed the entire venue into a magical space. We couldn’t have asked for a better team – professional, creative, and dedicated. Highly recommend them!</p>
                   
                    <div className='flex gap-1 text-[#cebd5f]'>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    </div>
                    <h2>Shakti Aujha</h2>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='client'>
               
                <div className='client-text flex flex-col items-center gap-1'>
                    <p>
                    I hired DL Decoration for my son’s birthday party, and they exceeded all expectations. The balloon arrangements were creative, fun, and brought the party to life. Every guest was amazed by the designs. DL Decoration is the go-to for any event decoration in Delhi!</p>
                   
                    <div className='flex gap-1 text-[#cebd5f]'>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    </div>
                    <h2>Anjali Sharma</h2>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='client'>
               
                <div className='client-text flex flex-col items-center gap-1'>
                    <p>
                    Our corporate event was a huge success, thanks to DL Decoration. Their attention to detail and unique balloon decor made a lasting impression on all attendees. They worked efficiently and ensured everything was in place on time. We will definitely use their services again!</p>
                    <div className='flex gap-1 text-[#cebd5f]'>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    </div>
                    <h2>Ravi Gupta</h2>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='client'>
               
                <div className='client-text flex flex-col items-center gap-1'>
                    <p>
                    DL Decoration transformed our small gathering into an extraordinary celebration. The balloon decorations were beautiful, and the team was so easy to work with. Their creativity and professionalism made the whole process stress-free. I highly recommend DL Decoration for any event in Delhi!</p>
                    <div className='flex gap-1 text-[#cebd5f]'>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    </div>
                    <h2>Sneha Kapoor</h2>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='client'>
               
                <div className='client-text flex flex-col items-center gap-1'>
                    <p>
                    DL Decoration made my baby shower absolutely magical! The pastel balloon arches and custom designs were just what I envisioned. They brought so much creativity and warmth to the event, and everyone loved the decor! I couldn’t have asked for a better experience. Thank you for making this day so special!</p>
                    <div className='flex gap-1 text-[#cebd5f]'>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    </div>
                    <h2>Megha Verma</h2>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='client'>
                <div className='client-text flex flex-col items-center gap-1'>
                    <p>DL Decoration did an incredible job for our 10th wedding anniversary! The elegant balloon decorations created a romantic atmosphere that really set the tone for the celebration. Every detail was beautifully executed, and the team was professional and easy to work with. We received so many compliments. Highly recommend!</p>
                    <div className='flex gap-1 text-[#cebd5f]'>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    </div>
                    <h2>Rohit & Kavita Sharma</h2>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='client'>
                <div className='client-text flex flex-col items-center gap-1'>
                    <p>DL Decoration made our baby’s homecoming a memorable event with their stunning ‘Welcome Baby Kavana’ balloon decor. The attention to detail was amazing – from the adorable baby-themed balloons to the personalized touches. Our guests were in awe of the setup, and we couldn’t be happier with their work!</p>
                    <div className='flex gap-1 text-[#cebd5f]'>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    <i class="ri-star-fill"></i>
                    </div>
                    <h2>Ananya & Raj</h2>
                </div>
            </div>
        
        </SwiperSlide>
      </Swiper>
        </div>
    </div>
  )
}

export default Testimonial