import React from 'react'
import What from './whatsapp.png'

export const Whatsapp = () => {
  return (
    <section className='whatsapp fixed bottom-2 left-1 w-[50px] h-[50px] md:w-[100px]h-[100px] z-50 '>
        <a target='blank' href='https://wa.me/c/919315453989'>
            <img className='w-full h-full object-cover object-center ' src={What} alt='whatsapp' />
        </a>
    </section>
  )
}
